import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Box } from '@material-ui/core'
import ConfirmContent from "../components/confirm/ConfirmContent";


const Confirm = props => {
  return (
    <Layout>
      <Seo title="Incubit" />

      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        overflow="hidden"
        position="relative"
        bgcolor={`rgba(0,0,0,.87)`}        
      >
      
        <ConfirmContent />        
      </Box>
    </Layout>
  )
}

export default Confirm
